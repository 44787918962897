import React, { useEffect, useRef } from 'react';
import { fromJS } from 'immutable';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell, EmptyState } from '@tradetrax/web-common';
import { AssigneeSelect } from '@tradetrax/web-common/lib/AssigneeSelect';
import { useAppContext } from 'app/AppContext';
import { MINIMUM_BATCH_SIZE } from './IntakeController';
import navigate from 'app/navigate';
import cn from 'classnames';
import { handleTableScrollStickyColumn2 } from '@tradetrax/web-common/lib/utils';
import { SelectAllCheckbox } from '@tradetrax/web-common/lib/OuttakeIntake/SelectAllCheckbox';

const COL_WIDTHS = {
  span: 32,
  jobName: 18 * 16,
  assignee: 16 * 16,
  taskName: 16 * 15,
};

export function IntakeTasks({ tab, communityId, state, controller, loaderRef, filterContext }) {
  const appContext = useAppContext();
  const { account, appState, hasPermission } = appContext;
  const { tasks, totalCount, maxCount } = state.get('allTasks').toObject();
  const { isFiltering } = filterContext;
  const title = isFiltering ? 'No Matches Found' : 'No Tasks Added';
  const body = isFiltering
    ? "When there's a result that meets the filters, it will appear here."
    : 'When a Task of a Job from this Community is assigned to your account it will appear here.';
  const isEmpty = totalCount === 0;
  const loggedUserId = appState.getIn(['user', '_id']);
  const userType = `user${tab.charAt(0).toUpperCase()}${tab.slice(1)}`;
  const scrollDivRef = useRef(null);
  const canAssignUsers = hasPermission('task_user_assignment');
  const selectedTasks = state.getIn(['selectedTasks', tab]);

  const rowClassName = index => {
    const task = tasks.get(index);
    const className = !task || task === EMPTY_ROW ? 'loading' : '';
    if (index < 0 || !task) return className;
    const isSelected =
      selectedTasks.get('taskIds').indexOf(task.get('id')) >= 0 ||
      (selectedTasks.get('selectAll') && selectedTasks.get('notIncludeTaskIds').indexOf(task.get('id')) < 0);
    return `${className} ${isSelected ? 'selected' : ''}`;
  };

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) element.addEventListener('scroll', handleTableScrollStickyColumn2);

    return () => {
      if (element) element.removeEventListener('scroll', handleTableScrollStickyColumn2);
    };
  }, []);
  useEffect(() => {
    handleTableScrollStickyColumn2({ target: scrollDivRef.current }, scrollDivRef);
  }, [tasks]);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={params => controller.readCommunityTasks({ communityId, tab, ...params })}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        ref={loaderRef}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowX: 'auto' }} className={cn({ 'd-none': isEmpty })} ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1100 ? width : 1100;
                return (
                  <Table
                    className="trx-table stickyTable stickyColumn2"
                    estimatedRowSize={80}
                    headerHeight={40}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    overscanRowCount={2}
                    ref={registerChild}
                    rowClassName={({ index }) => rowClassName(index)}
                    rowCount={Math.min(maxCount, totalCount)}
                    rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                    rowHeight={80}
                    width={minWidth}
                    onScroll={event => handleTableScrollStickyColumn2(event, scrollDivRef)}
                  >
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                    <Column
                      dataKey="task"
                      minWidth={350}
                      width={COL_WIDTHS.taskName}
                      headerClassName="d-flex align-items-center"
                      headerRenderer={() => (
                        <SelectAllCheckbox
                          selectedTasks={selectedTasks}
                          controller={controller}
                          show={canAssignUsers}
                        />
                      )}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const isChecked = selectedTasks.get('taskIds').indexOf(rowData.get('id')) >= 0;
                        const isExcluded = selectedTasks.get('notIncludeTaskIds').indexOf(rowData.get('id')) >= 0;
                        return (
                          <div className="d-flex align-items-center">
                            <input
                              className={cn('mr-2', { 'd-none': !canAssignUsers })}
                              type="checkbox"
                              checked={(selectedTasks.get('selectAll') || isChecked) && !isExcluded}
                              onChange={({ target }) =>
                                controller.onSelectCheckbox({
                                  checked: target.checked,
                                  task: rowData,
                                  tab,
                                })
                              }
                            />
                            <span className="text-truncate font-weight-bold">{cellData.get('name')}</span>
                          </div>
                        );
                      })}
                    />
                    <Column
                      label="Job"
                      dataKey="job"
                      width={COL_WIDTHS.jobName}
                      cellRenderer={renderCell(({ cellData }) => {
                        const lotNumber = cellData.get('lotNumber');
                        return (
                          <>
                            <Link
                              className="secondary-link"
                              to={navigate.to.JobDetails({ jobId: cellData.get('id') }, false)}
                            >
                              <div className="text-truncate">{cellData.get('name')}</div>
                            </Link>
                            {lotNumber && (
                              <span className="d-block text-muted text-truncate">{`LOT #${lotNumber}`}</span>
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label={`${tab.charAt(0).toUpperCase()}${tab.slice(1)}`}
                      dataKey="task"
                      width={COL_WIDTHS.assignee}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const assigneeId = cellData.getIn([userType, '_id']);
                        const assignee = assigneeId
                          ? fromJS({
                              _id: assigneeId,
                              name: cellData.getIn([userType, 'name']),
                            })
                          : null;
                        return (
                          <AssigneeSelect
                            index={rowIndex}
                            users={account.get('assignees')}
                            options={account.get('regularUsersActive')}
                            currentUser={assignee}
                            hasPermission={canAssignUsers}
                            loggedUserId={loggedUserId}
                            onChange={assignee =>
                              controller.assignUserToTask({ rowData, tab, assignee: fromJS(assignee) })
                            }
                          />
                        );
                      })}
                    />
                    <Column dataKey="" label="" width={COL_WIDTHS.span} />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
      {isEmpty && <EmptyState icon="wrench" title={title} body={body} />}
    </>
  );
}
