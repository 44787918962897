import React from 'react';
import { TimeTrackerContext } from './TimeTrackerContext';
import { StartButton } from './StartButton';
import { WorkingPanel } from './WorkingPanel';
import { BreakPanel } from './BreakPanel';

export const TimeTracker = ({ appContext }) => {
  const context = TimeTrackerContext(appContext);
  const { state, controller } = context;
  const currentState = state.get('currentState');

  if (currentState === 'not-started' || currentState === 'end') return <StartButton controller={controller} />;
  if (currentState === 'working') return <WorkingPanel context={context} />;
  if (currentState === 'break') return <BreakPanel context={context} />;

  return null;
};
