import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col } from 'react-bootstrap';
import { subsService } from 'services';
import { EmptyState, useCommunities, useTaskTypes, colors } from '@tradetrax/web-common';
import { plural } from '@tradetrax/web-common/lib/utils';
import { DashboardLoadingState } from '@tradetrax/web-common/lib/Dashboard/Loading';
import { UpdateRequestCard, OverdueTaskCard } from '@tradetrax/web-common/lib/Dashboard/Requests/RequestCard';
import { BulkHeader, BulkOverdueContext } from '@tradetrax/web-common/lib/BulkActions';
import { getOverdueActions, getUpdateRequestActions } from '@tradetrax/web-common/lib/BulkActions/common';
import { RequestAndOverdueDetailsContext, BULK_REQUESTS, BULK_OVERDUE } from './RequestAndOverdueDetailsContext';
import navigate, { Paths } from 'app/navigate';
import { useAppContext } from 'app/AppContext';
import { FilterImplementation } from '@tradetrax/web-common/lib/Filters/Implementation/Filter.implementation';
import { FilterSets } from '@tradetrax/web-common/lib/Filters/FilterSets';

export function RequestAndOverdueDetails() {
  const appContext = useAppContext();
  const { hasPermission, getPermission, appState } = appContext;
  const { user } = appState.toObject();
  const context = RequestAndOverdueDetailsContext();
  const { state, controller, filterContext } = context;
  const { tasksUpdateRequest, tasksOverdue, isManageTasks, disabledBulk } = state.toObject();
  const tasksSize = tasksUpdateRequest.size + tasksOverdue.size;
  const communities = useCommunities(subsService);
  const taskTypes = useTaskTypes(subsService);
  const hideManageTasks =
    !tasksSize || !(getPermission('manage_update_request') !== 'no' || getPermission('task_update_status') !== 'no');
  const lookups = { communities, taskTypes };

  const props = {
    user,
    context,
    controller,
    isManageTasks,
    hasPermission,
  };

  return (
    <>
      <Breadcrumb className="mx-4 mt-3" tag="nav">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: Paths.DashboardV2 }}>
          Dashboard
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Update Request and Overdue Tasks</Breadcrumb.Item>
      </Breadcrumb>
      <div className="mx-4 mb-4 d-flex flex-row justify-content-between">
        <h4 className="font-weight-bold">Update Request and Overdue Tasks</h4>
      </div>
      <FilterSets.Panel filterContext={filterContext} visible>
        <span
          className={cn('btn btn-link mr-4', { 'd-none': hideManageTasks })}
          onClick={controller.toggleManageTasks}
        >{`${isManageTasks ? 'Close ' : ''}Manage Tasks`}</span>
        <FilterImplementation
          top="10.2rem"
          lookups={lookups}
          filterContext={filterContext}
          title="Update Requests & Overdue Tasks Filters"
          disabled={isManageTasks}
        >
          <FilterSets.Section filterContext={filterContext} />
        </FilterImplementation>
      </FilterSets.Panel>
      <Row className="mx-4">
        <Col xl={5} lg={7} md={9} sm={12} className="mr-1">
          <UpdateRequests
            tasksUpdateRequest={tasksUpdateRequest}
            markAsSeen={controller.markChangeRequestAsSeen}
            isDisabled={disabledBulk === BULK_REQUESTS}
            {...props}
          />
        </Col>
        <Col xl={5} lg={7} md={9} sm={12}>
          <OverDueTasks
            tasksOverdue={tasksOverdue}
            markAsSeen={controller.markTaskAsSeen}
            isDisabled={disabledBulk === BULK_OVERDUE}
            getPermission={appContext.getPermission}
            {...props}
          />
        </Col>
      </Row>
    </>
  );
}

const UpdateRequests = ({ tasksUpdateRequest, markAsSeen, isManageTasks, context, ...rest }) => {
  const bulkContext = BulkOverdueContext(subsService, context);
  const bulkActions = getUpdateRequestActions(bulkContext);
  const linkToTask = card => {
    const jobId = card.getIn(['job', 'id']);
    const taskId = card.get('id');
    return {
      to: {
        pathname: navigate.to.TaskDetails({ jobId, taskId, status: 'open' }, false),
        state: { referrer: 'dashboard' },
      },
      onClick: () => markAsSeen({ jobId, taskId }),
    };
  };

  if (tasksUpdateRequest.get('isLoading')) return <DashboardLoadingState />;

  if (tasksUpdateRequest.size === 0)
    return (
      <EmptyState
        icon="bell"
        title="No Update Requests"
        body="When an Installer proposes a schedule change an Update Request will appear here."
      />
    );

  return (
    <>
      <TitleTasks className="pb-3" isManageTasks={isManageTasks}>
        <strong>{`${tasksUpdateRequest.size} Update ${plural(tasksUpdateRequest.size, 'Request')}`}</strong>
      </TitleTasks>
      <BulkHeader
        context={bulkContext}
        show={isManageTasks}
        emptyTitle="Select multiple Tasks to respond to their Update Requests."
        actions={bulkActions}
      >
        {tasksUpdateRequest.map((card, index) => (
          <UpdateRequestCard
            key={index}
            card={card}
            linkToTask={linkToTask(card)}
            isManageTasks={isManageTasks}
            isDetails
            {...rest}
          />
        ))}
      </BulkHeader>
    </>
  );
};

const OverDueTasks = ({ tasksOverdue, markAsSeen, isManageTasks, context, getPermission, ...rest }) => {
  const bulkContext = BulkOverdueContext(subsService, context);
  const bulkActions = getOverdueActions(bulkContext, getPermission);
  const linkToTask = card => {
    const jobId = card.getIn(['job', 'id']);
    const taskId = card.get('id');
    return {
      to: {
        pathname: navigate.to.TaskDetails({ jobId, taskId, status: 'open' }, false),
        state: { referrer: 'dashboard' },
      },
      onClick: () => markAsSeen({ jobId, taskId }),
    };
  };

  if (tasksOverdue.get('isLoading')) return <DashboardLoadingState />;

  if (tasksOverdue.size === 0)
    return (
      <EmptyState
        icon="circle-exclamation"
        title="No Overdue Tasks"
        body="When a start or finish date is missed an Overdue Task will appear here."
      />
    );

  return (
    <div>
      <TitleTasks className="pb-3" isManageTasks={isManageTasks}>
        <strong>{`${tasksOverdue.size} Overdue ${plural(tasksOverdue.size, 'Task')}`}</strong>
      </TitleTasks>
      <BulkHeader
        context={bulkContext}
        show={isManageTasks}
        emptyTitle="Select multiple Tasks to resolve their missed dates."
        actions={bulkActions}
      >
        {tasksOverdue.map((card, index) => (
          <OverdueTaskCard
            {...rest}
            key={index}
            card={card}
            linkToTask={linkToTask(card)}
            isManageTasks={isManageTasks}
            isDetails
          />
        ))}
      </BulkHeader>
    </div>
  );
};

const TitleTasks = styled.div`
  ${props => `
  position: ${props.isManageTasks ? 'sticky' : 'static'};
 `}

  top: 24px;
  z-index: 100;
  background-color: ${colors.white};
`;
