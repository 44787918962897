import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { TimeSelect } from '@tradetrax/web-common';
import { UL } from './UL';
import { useAppContext } from 'app/AppContext';

const EVENTS = {
  'installer-change-request': 'Update Requests from Installers',
  'builder-change-request': 'Update Requests from Builders',
  'start-date-confirmation-request': 'Start Date Confirmation Requests',
  'commitment-request': 'Commitment Requests',
  'task-not-ready-to-start': 'Task not ready to start from Installers',
  'date-change': 'Start-Finish Date Changes',
  task_assignment: 'New tasks assigned to me',
  overdue: 'Overdue Tasks',
  'task-missed-check-in': 'Missed Check-in',
  'task-missed-check-out': 'Missed Check Out',
  'check-in-reminder': 'Check-in Reminder',
  'update-request-reminder': 'Update Request Reminder',
  'new-task-attachment-or-note': 'Notes/Attachments Added to Task',
  'tagged-in-notes': 'Tagged in Notes',
};

export const ReceiveUpdates = ({ ...props }) => {
  const { events, pushDisabled, emailDisabled, onChange } = props;
  const notice_window = events.getIn(['date-change', 'notice_window']);
  const noticeTime = events.getIn(['task-missed-check-in', 'noticeTime']);
  const noticeTimeCheckOut = events.getIn(['task-missed-check-out', 'noticeTime']);
  const noticeTimeUpdateReq = events.getIn(['update-request-reminder', 'noticeTime']);
  const noticeTimeCheckInReminder = events.getIn(['check-in-reminder', 'noticeTime']);

  const { hasPermission } = useAppContext();

  return (
    <div className="d-flex flex-column p-2">
      <div className="d-flex flex-row justify-content-between">
        <h5 className="font-weight-bold mb-3">Receive Updates</h5>
        <div className="font-weight-bold">
          <span>Push</span>
          <span className="mr-2 ml-4">Email</span>
        </div>
      </div>
      <UL>
        <Update event="installer-change-request" {...props} />
        <Update event="builder-change-request" {...props} />
        <Update event="start-date-confirmation-request" {...props} />
        {hasPermission('commitment_request_management') ? <Update event="commitment-request" {...props} /> : null}
        <Update event="task-not-ready-to-start" {...props} />
        <Update event="date-change" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-center mt-2">
              <Form.Control
                type="number"
                size="sm"
                min={0}
                max={99}
                style={{ width: 55 }}
                defaultValue={notice_window}
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onChange={e => onChange('date-change', { notice_window: parseInt(e.target.value, 10) || 1 })}
              />
              <span className="small text-secondary ml-2 pt-1">Days in advance notice window</span>
            </div>
          )}
        </Update>
        <Update event="task_assignment" {...props} />
        <Update event="overdue" {...props} />
        <Update event="task-missed-check-in" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('task-missed-check-in', { noticeTime: time || '2 pm' })}
                selected={(noticeTime && moment({ hours: noticeTime }).format('h a')) || '2 pm'}
              />
            </div>
          )}
        </Update>
        <Update event="task-missed-check-out" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('task-missed-check-out', { noticeTime: time || '2 pm' })}
                selected={(noticeTimeCheckOut && moment({ hours: noticeTimeCheckOut }).format('h a')) || '2 pm'}
                event="task-missed-check-out"
              />
            </div>
          )}
        </Update>
        <Update event="check-in-reminder" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('check-in-reminder', { noticeTime: time || '8 am' })}
                selected={
                  (noticeTimeCheckInReminder && moment({ hours: noticeTimeCheckInReminder }).format('h a')) || '8 am'
                }
              />
            </div>
          )}
        </Update>
        <Update event="update-request-reminder" {...props}>
          {({ pushChecked, emailChecked }) => (
            <div className="d-flex flex-row justify-content-start mt-2">
              <TimeSelect
                disabled={(pushDisabled && emailDisabled) || (!pushChecked && !emailChecked)}
                onSelect={time => onChange('update-request-reminder', { noticeTime: time || '2 pm' })}
                selected={(noticeTimeUpdateReq && moment({ hours: noticeTimeUpdateReq }).format('h a')) || '2 pm'}
              />
            </div>
          )}
        </Update>
        <Update event="new-task-attachment-or-note" {...props} />
        <Update event="tagged-in-notes" {...props} />
      </UL>
    </div>
  );
};

const Update = ({ event, children, events, onChange, pushDisabled, emailDisabled }) => {
  const channels = events.getIn([event, 'onlyChannels']);
  const pushChecked = channels && channels.indexOf('push') >= 0;
  const emailChecked = channels && channels.indexOf('email') >= 0;
  const update = onlyChannels => onChange(event, { onlyChannels });

  return (
    <li>
      <div className="d-flex flex-column mr-auto">
        <span>{EVENTS[event]}</span>
        {children && children({ pushChecked, emailChecked })}
      </div>
      <Form.Check
        className="mr-1"
        id={`${event}_push`}
        checked={!!pushChecked}
        disabled={pushDisabled}
        type="switch"
        label=""
        onChange={({ target }) => update([target.checked ? 'push' : null, emailChecked ? 'email' : null])}
      />
      <Form.Check
        className="ml-3"
        id={`${event}_email`}
        checked={!!emailChecked}
        disabled={emailDisabled}
        type="switch"
        label=""
        onChange={({ target }) => update([target.checked ? 'email' : null, pushChecked ? 'push' : null])}
      />
    </li>
  );
};
