import React from 'react';
import styled from 'styled-components';
import { ConfirmCancelPopover } from '@tradetrax/web-common/lib/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { EmptyState } from '@tradetrax/web-common/lib/EmptyState';
import { useAppContext } from 'app/AppContext';

const WIDTHS = {
  type: 16 * 20,
  shared: 16 * 13,
  delete: 16 * 3,
};

const FILTERSET_TYPE_MAP = {
  jobSchedule: 'Job Schedule',
  taskList: 'Task List',
};

export function FilterSets({ filterSets, loggedUser, controller }) {
  const { isUserInactive } = useAppContext();
  const isLoggedUser = compareUserId(loggedUser);
  const isManager = loggedUser.get('role') === 'manager';
  const isEmpty = !filterSets.size;
  const [show, setShow] = React.useState(false);
  const [currentPopover, setCurrentPopover] = React.useState({ target: null, filterSet: null });
  const containerRef = React.useRef();

  const onConfirm = () => {
    controller.removeFilterSet(currentPopover.filterSet);
    setShow(false);
  };

  return (
    <>
      <div className="d-flex flex-column mb-5">
        <span className="text-gray-800 px-4">
          Here is the list of Filter Sets that have been shared with this Custom Role. To add more Filter Sets to this
          Custom Role, go to the Manage Filter Sets modal.
        </span>
      </div>
      {isEmpty ? (
        <EmptyState
          icon="wrench"
          title="No Filter Sets Shared"
          body="When a Filter Set is added or shared with this Custom Role, it will appear here."
        />
      ) : (
        <>
          <UL className="header">
            <li className="pb-1">
              <span className="mr-auto">Filter Set Name</span>
              <Col width={WIDTHS.type + 8}>Type</Col>
              <Col width={WIDTHS.shared + 12}>Shared</Col>
              <Col width={WIDTHS.delete} />
            </li>
          </UL>
          <UL ref={containerRef}>
            <ConfirmCancelPopover
              show={show}
              target={currentPopover.target}
              containerRef={containerRef}
              confirmText="Remove"
              message="Once removed, all users created with this Custom Role will lose access to this Filter Set."
              onConfirm={onConfirm}
              onCancel={() => setShow(false)}
            />
            {filterSets.map((filterSet, index) => (
              <FilterItem
                index={index}
                key={filterSet.get('_id')}
                filterSet={filterSet}
                target={currentPopover.target}
                isLoggedUser={isLoggedUser}
                isUserInactive={isUserInactive}
                setTarget={setCurrentPopover}
                setShow={setShow}
                isManager={isManager}
              />
            ))}
          </UL>
        </>
      )}
    </>
  );
}

const compareUserId = loggedUser => sharedBy => loggedUser.get('_id') === sharedBy.get('_id');

const reachPopoverBtn = node => {
  if (node.classList.contains('popover-trigger')) return node;
  return reachPopoverBtn(node.parentElement);
};

const FilterItem = ({ index, filterSet, isLoggedUser, isUserInactive, target, setTarget, setShow, isManager }) => {
  const { sharedBy } = filterSet.toObject();

  const sharedByName = () => {
    const name = sharedBy.get('name');
    if (isLoggedUser(sharedBy)) return 'Shared by me';
    if (!isUserInactive(sharedBy)) return `Shared by ${name}`;

    return (
      <>
        Shared by
        <span className="text-nowrap text-truncate deactivated-user ml-1">
          <FontAwesomeIcon icon="ban" className="mr-1" />
          {name}
        </span>
      </>
    );
  };

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const newTarget = reachPopoverBtn(e.target);
    if (target === newTarget) setShow(show => !show);
    else {
      setShow(true);
      setTarget({ target: newTarget, filterSet });
    }
  };

  return (
    <li>
      <span className="mr-auto text-gray-800">{filterSet.get('name')}</span>
      <Col width={WIDTHS.type}>{FILTERSET_TYPE_MAP[filterSet.get('type')]}</Col>
      <Col className="font-size-12 mr-3 text-secondary" width={WIDTHS.shared}>
        <FontAwesomeIcon icon="user-group" className="mr-2" />
        {sharedByName()}
      </Col>
      <Col width={WIDTHS.delete}>
        {isManager && (
          <button
            id={`delete-btn-${index}`}
            className="btn btn-lnk text-secondary action ml-1 my-1 popover-trigger"
            onClick={handleClick}
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        )}
      </Col>
    </li>
  );
};

const Col = styled.span`
  ${({ width }) => `
        width: ${width}px;
    `}
`;

const UL = styled.ul`
  color: ${colors.gray400};
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  margin: 0px;
  overflow: auto;

  &.header {
    padding-bottom: 0;
    color: ${colors.gray400};
    font-weight: 700;
    font-size: 12px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px 30px;
    border-bottom: solid 1px ${colors.gray100};

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  button.action:hover:not([disabled]) {
    background-color: ${colors.activeState};
    color: ${colors.blue300} !important;
  }
`;
