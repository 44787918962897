import React from 'react';
import { Link } from 'react-router-dom';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import navigate from 'app/navigate';
import { CommunityPageController } from './CommunityPageController';
import { plural } from '@tradetrax/web-common/lib/utils';

const COL_WIDTHS = {
  span: 32,
  builder: 128,
  tasks: 128,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export const CommunityPage = () => {
  const { state, controller } = CommunityPageController();
  const communities = state.get('communities');
  const totalCount = state.get('totalCount');

  return (
    <>
      <div className="mx-4 my-4 d-flex flex-row justify-content-between">
        <h4 className="font-weight-bold">Communities</h4>
      </div>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!communities.get(index)}
        loadMoreRows={controller.loadMoreRows}
        rowCount={totalCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto' }}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Table
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    className="trx-table stickyColumn2"
                    headerHeight={40}
                    width={width}
                    height={height}
                    onRowClick={() => {}}
                    overscanRowCount={2}
                    rowHeight={80}
                    rowCount={totalCount}
                    rowClassName={({ index }) => (communities.get(index) ? '' : 'loading')}
                    rowGetter={({ index }) => communities.get(index) || EMPTY_ROW}
                    noRowsRenderer={() => (
                      <EmptyStateCenterContainer top="150px">
                        <EmptyState
                          icon="house"
                          title="No Communities Assigned"
                          body="When a Community is assigned to your account it will appear here."
                        />
                      </EmptyStateCenterContainer>
                    )}
                  >
                    <Column dataKey="" width={COL_WIDTHS.span} label="" />

                    <Column
                      label="Name"
                      dataKey="name"
                      minWidth={230}
                      width={width - WIDTHS}
                      cellRenderer={renderCell(({ cellData, rowIndex }) => (
                        <Link
                          className="main-link  w-100 d-inline-block text-truncate"
                          to={{
                            pathname: navigate.to.CommunityDetails(
                              { communityId: communities.getIn([rowIndex, '_id']) },
                              false
                            ),
                            state: {
                              referrer: 'jobs',
                              defaultTab: 'schedule',
                            },
                          }}
                        >
                          <div className="text-truncate">{cellData}</div>
                        </Link>
                      ))}
                    />

                    <Column
                      label="Builder Account"
                      dataKey="builderAccountName"
                      minWidth={150}
                      width={COL_WIDTHS.builder}
                      cellRenderer={renderCell(({ cellData }) => (
                        <div className="text-truncate">{cellData}</div>
                      ))}
                    />
                    <Column
                      label="Tasks Assigned"
                      dataKey="assignedTasks"
                      width={COL_WIDTHS.tasks}
                      cellRenderer={renderCell(({ cellData }) => (
                        <div className="text-truncate"> {`${cellData} ${plural(cellData, 'task', 'tasks')}`}</div>
                      ))}
                    />

                    <Column dataKey="" width={COL_WIDTHS.span} label="" />
                  </Table>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
    </>
  );
};
