import React from 'react';
import { markAsSideEffect, CustomDialog, OKDialog } from '@tradetrax/web-common';
import { WorkSummary } from './WorkSummary';
import { subsService } from 'services';
import moment from 'moment';

const TIME_LOG_EVENTS = {
  CLOCK_IN: 'clock-in',
  CLOCK_OUT: 'clock-out',
  START_BREAK: 'start-break',
  END_BREAK: 'end-break',
};

markAsSideEffect(initialize);
export function initialize() {
  subsService.getCurrentStatuss().then(status => this.controller.dispatch([state => state.merge(status)]));
}

export function start() {
  const event = TIME_LOG_EVENTS.CLOCK_IN;
  const currentLocalDay = moment().format('YYYY-MM-DD');

  return subsService
    .addTimeLogs({ currentLocalDay }, { params: { event } })
    .then(status => state => state.merge(status))
    .catch(ex => {
      this.controller.initialize();
      throw ex;
    });
}

export function _break() {
  const event = TIME_LOG_EVENTS.START_BREAK;
  const currentLocalDay = moment().format('YYYY-MM-DD');

  return subsService
    .addTimeLogs({ currentLocalDay }, { params: { event } })
    .then(status => state => state.merge(status))
    .catch(ex => {
      this.controller.initialize();
      throw ex;
    });
}

export function stopBreak() {
  const event = TIME_LOG_EVENTS.END_BREAK;
  const currentLocalDay = moment().format('YYYY-MM-DD');

  return subsService
    .addTimeLogs({ currentLocalDay }, { params: { event } })
    .then(status => state => state.merge(status))
    .catch(ex => {
      this.controller.initialize();
      throw ex;
    });
}

markAsSideEffect(endDay);
export async function endDay() {
  const { isAccept } = await openEndDayDialog(this.modal);
  if (!isAccept) return;

  const event = TIME_LOG_EVENTS.CLOCK_OUT;
  const currentLocalDay = moment().format('YYYY-MM-DD');

  subsService
    .addTimeLogs({ currentLocalDay }, { params: { event } })
    .then(summary => {
      if (summary.currentState === 'not-started') {
        const from = moment(summary.clockInDate)
          .startOf('day')
          .format();
        const to = moment(summary.clockOutDate)
          .endOf('day')
          .format();
        setTimeout(() => {
          subsService
            .getUserWorkingSessions({}, { query: { from, to } })
            .then(([sessions]) => openWorkSummary(this.modal, sessions));

          this.alert.success({ message: 'Time tracking successfully ended for now.', size: 'm' });
        }, 1);
      }

      this.controller.dispatch([state => state.merge(summary)]);
    })
    .catch(() => {
      this.alert.error({ message: 'There was a problem ending time tracking. Please try again.', size: 'm' });
      this.controller.initialize();
    });
}

function openEndDayDialog(modal) {
  return modal.open(CustomDialog, {
    centered: true,
    title: 'End Day',
    message: 'Are you sure to end this Day?',
    titleAccept: 'Yes, End Day',
    titleCancel: 'Cancel',
  });
}

function openWorkSummary(modal, sessions) {
  const { workingSessions } = sessions;
  const [summary] = workingSessions;
  const clockInDate = moment(summary.clockInDate);
  const clockOutDate = moment(summary.clockOutDate);

  let title = `Time Summary: ${clockInDate.format('dddd, MMMM D')}`;
  if (!clockInDate.isSame(clockOutDate, 'day')) title += clockOutDate.format(' - dddd, MMMM D');
  if (workingSessions.length > 1) title += ` (${workingSessions.length})`;

  return modal.open(OKDialog, {
    title,
    centered: true,
    dialogClassName: 'width-600',
    message: <WorkSummary summary={summary} />,
    cancelProps: { hidden: true },
  });
}
